<template>
    <Disclosure
        as="div"
        defaultOpen
        v-slot="{ open }"
    >
        <dt
            class="text-md mt-2 pt-2"
            :style="[borderStyle]"
        >
            <div class="text-left flex flex-row items-center text-gray-400">
                <DisclosureButton
                    class="ml-0 flex items-center rounded-lg py-1 bg-transparent border-2 border-transparent hover:bg-gray-50 focus:bg-gray-50 focus:ring-gray-50 focus:border-gray-50"
                >
                    <span class="font-medium text-gray-900">
                        {{ title }}
                    </span>
                    <div class="ml-3 box-content h-5 w-5 p-0 m-0">
                        <ChevronDownIcon
                            :class="[
                                open ? '-rotate-180' : 'rotate-0',
                                'h-5 w-5 transform rounded-full',
                            ]"
                            aria-hidden="true"
                        />
                    </div>
                </DisclosureButton>
            </div>
        </dt>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-150 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <DisclosurePanel
                as="dd"
                :unmount="false"
                class="mt-2"
                v-show="!suppressAlerts && alerts.length > 0"
            >
                <slot
                    name="alerts"
                    :open="open"
                >
                    <!-- FALLBACK CONTENT -->
                    <div class="grid grid-cols-1">
                        <BaseAlert
                            :alerts="alerts"
                            @dismiss:alert="
                                (e) => {
                                    $emit('dismiss:alert', e);
                                }
                            "
                        />
                    </div>
                </slot>
            </DisclosurePanel>
        </transition>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform -translate-y-10 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-out"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-10 opacity-0"
        >
            <DisclosurePanel
                as="dd"
                class="mt-2"
            >
                <slot
                    name="content"
                    :open="open"
                >
                    <!-- FALLBACK CONTENT -->
                    Filter content goes here.
                </slot>
            </DisclosurePanel>
        </transition>
    </Disclosure>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs } from 'vue';

    // <!-- COMPONENTS -->
    import {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
    } from '@headlessui/vue';
    import { ChevronDownIcon } from '@heroicons/vue/outline';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';

    // <!-- COMPOSABLES -->
    import { DismissAlertEvent } from '@/components/alerts/events';

    // <!-- TYPES -->
    /** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SidebarFilter',
        components: {
            Disclosure,
            DisclosureButton,
            DisclosurePanel,
            BaseAlert,
            ChevronDownIcon,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** If true, show the top border. */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            alerts: {
                /** @type {V.PropType<AlertDef[]>} */
                type: Array,
                default: () => [],
            },
            /** If true, do not ever show alerts for this filter. */
            suppressAlerts: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: [DismissAlertEvent],
        setup(props) {
            // STATE
            const { border } = toRefs(props);

            // PROPERTIES
            const borderStyle = computed(() => {
                const isBorderVisible = border.value;
                return isBorderVisible
                    ? 'border-top: 1px solid rgba(0,0,0,0.1)'
                    : 'border-top: none';
            });

            // EXPOSE
            return {
                borderStyle,
            };
        },
    });
</script>
