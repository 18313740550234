// <!-- UTILITIES -->
import { min, max } from 'date-fns';

/**
 * Get earliest start date from a pair of intervals.
 * @param {Readonly<Interval>} a
 * @param {Readonly<Interval>} b
 * @returns {DateLike}
 */
const getEarliestStartDate = (a, b) => {
    const isStartDateAValid = !Number.isNaN(a.start.valueOf());
    const isStartDateBValid = !Number.isNaN(b.start.valueOf());
    if (isStartDateAValid && isStartDateBValid) {
        // When both are valid, return the minimum.
        return min([a.start, b.start]);
    }
    if (!isStartDateAValid && !isStartDateBValid) {
        // When neither are valid, return `NaN`.
        return NaN;
    }
    // When only one is valid, return the valid one.
    return isStartDateAValid ? a.start : b.start;
};

/**
 * Get latest end date from a pair of intervals.
 * @param {Readonly<Interval>} a
 * @param {Readonly<Interval>} b
 * @returns {DateLike}
 */
const getLatestEndDate = (a, b) => {
    const isEndDateAValid = !Number.isNaN(a.end.valueOf());
    const isEndDateBValid = !Number.isNaN(b.end.valueOf());
    if (isEndDateAValid && isEndDateBValid) {
        // When both are valid, return the maximum.
        return max([a.end, b.end]);
    }
    if (!isEndDateAValid && !isEndDateBValid) {
        // When neither are valid, return `NaN`.
        return NaN;
    }
    // When only one is valid, return the valid one.
    return isEndDateAValid ? a.end : b.end;
};

/**
 * Get the earliest start date and latest end date from a collection of intervals.
 * @param {Iterable<Readonly<[ start: DateLike, end: DateLike ]>>} [intervals]
 * @returns {Interval}
 */
export const getDateRangeTotal = (intervals = []) => {
    /** @type {Interval} */
    const fullRange = { start: NaN, end: NaN };
    for (const [start, end] of intervals ?? []) {
        const interval = { start, end };
        fullRange.start = getEarliestStartDate(fullRange, interval);
        fullRange.end = getLatestEndDate(fullRange, interval);
    }
    // If no intervals are provided, return default range. Otherwise, returns modified interval.
    return fullRange;
};

// <!-- DEFAULT -->
export default getDateRangeTotal;
