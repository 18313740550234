<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
    >
        <div class="text-white">
            <MenuButton
                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                :class="[`${background}`]"
                :style="{
                    'z-index': 9,
                }"
            >
                <span :class="[`${text}`]">{{ label }}</span>
                <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    :class="[`${text}`]"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="origin-top-right absolute right-0 w-56 mt-11 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                :style="{
                    'z-index': 9,
                }"
            >
                <slot></slot>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
    import { ChevronDownIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ActionsMenu',
        components: {
            Menu,
            MenuButton,
            MenuItems,
            ChevronDownIcon,
        },
        props: {
            label: {
                type: String,
                default: 'Actions',
            },
            background: {
                type: String,
                default: 'bg-primary-600',
            },
            text: {
                type: String,
                default: 'text-white',
            },
        },
    });
</script>
