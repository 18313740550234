<template>
    <TeleportModal
        to="#modals"
        size="xl"
        theme="primary"
        title="NARA Standards"
        subtitle="Review current NARA standards."
        :show="show"
        :busy="!!isLoading || !!busy"
        :disabled="disabled"
        @close="$emit('close')"
        hideCancelAction
    >
        <template #header></template>
        <template #default="{ theme, disabled, busy }">
            <ModalContent
                :theme="theme"
                :disabled="disabled"
                :busy="busy"
            >
                <template #default>
                    <!-- PANEL -->
                    <div class="w-full mx-auto">
                        <AgGridVue
                            class="ag-theme-alpine"
                            domLayout="autoHeight"
                            :pagination="true"
                            :paginationPageSize="25"
                            :columnDefs="viewColDefs"
                            :rowData="rowData"
                            :defaultColDef="defaultColDef"
                            :rowHeight="null"
                            @grid-ready="onGridReady"
                            @column-resized="onColumnResized"
                            overlayNoRowsTemplate="No standards to display."
                        />
                    </div>
                </template>
            </ModalContent>
        </template>
        <template #footer></template>
    </TeleportModal>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import TeleportModal from '@/components/modals/TeleportModal.vue';
    import ModalContent from '@/components/modals/ModalContent.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import { useNARAStandardManager } from '~NARAStandards/hooks/useNARAStandardManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ViewNARAStandardsModal',
        components: {
            TeleportModal,
            ModalContent,
            AgGridVue,
        },
        props: {
            /** Whether the modal should be shown or hidden. */
            show: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['close'],
        setup(props) {
            // <!-- DEFINE -->
            const manager = useNARAStandardManager();
            const { grid, data, actions } = manager;
            const { rowData, viewColDefs } = data;
            const defaultColDef = actions.getDefaultColDef();

            // <!-- CONDITIONALS -->
            const { isLoading } = data;

            // <!-- METHODS -->
            const { onGridReady, onColumnResized } = grid;
            const { refreshNARAStandards } = actions;

            // <!-- LIFECYCLE -->
            onMounted(async () => {
                // Refresh the NARAStandards on initial page load.
                await refreshNARAStandards(false);
            });

            // <!-- EXPOSE -->
            return {
                // STATE
                defaultColDef,
                rowData,
                viewColDefs,
                // CONDITIONALS
                isLoading,
                // METHODS
                onGridReady,
                onColumnResized,
            };
        },
    });
</script>
