// <!-- API -->
import { ref, computed, nextTick } from 'vue';
import {
    computedEager,
    createEventHook,
    resolveUnref,
    watchDebounced,
} from '@vueuse/core';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import compare from 'just-compare';
import { diff, jsonPatchPathConverter } from 'just-diff';
import {
    add,
    areIntervalsOverlapping,
    isBefore,
    isValid as isValidDate,
    sub,
} from 'date-fns';
import { DurationISO } from '@/utils/datetime';
import { DateRangeFilter } from '@/utils/filters';

// <!-- COMPOSABLES -->
import { Store, useStore } from 'vuex';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';
import { DropdownOption } from '@/utils/options';
import {
    getDateRangeOverlap,
    getDateRangeTotal,
} from '@/features/analysis/utils';

// <!-- CONSTANTS ->
/** The epoch date, January 1st, 1970. */
const EPOCH_DATE = new Date(0);

// <!-- DESTRUCTURE -->
const { FormDataModel, StoreDataModel } = DateRangeFilter;

// <!-- CONTROLLER CLASS -->
/**
 * Controller that drives the date range filter and its inputs.
 *
 * @implements {Filters.DateRange.Form.Controller}
 */
class Controller {
    // TYPE DEFINITIONS

    /** @typedef {Filters.DateRange.Form.Controller} FormController */

    // CONSTRUCTOR

    /**
     * Initializes the date range filter.
     */
    constructor() {
        // Provides dependencies.
        this.boot();

        // Defines controller interface.
        this.defineConstants();
        this.defineEvents();
        this.defineReactive();
        this.defineComputed();
        this.defineTriggers();
        this.defineHooks();
        this.defineQueries();
        this.defineWatchers();
        this.defineActions();
    }

    // CONTEXT | SERVICES

    boot() {
        /** @type {Filters.DateRange.Form.Controller['store']} */
        this.store = useStore();
    }

    // CONSTANTS

    /**
     * Define the filter constants.
     */
    defineConstants() {
        // ==== DEBUG ====
        {
            /**
             * Is debug mode?
             * @type {Readonly<boolean>}
             */
            this.IsDebug = process.env.NODE_ENV !== 'production';
        }

        // ==== FORM DATA ====
        {
            /** @type {string} */
            this.FormID = 'filter-daterange';

            /**
             * Collection of default preset durations.
             * @type {string[]}
             */
            this.PresetDurations = [
                'P1MT0S',
                'P3MT0S',
                'P6MT0S',
                'P1YT0S',
                'P2YT0S',
                'P3YT0S',
            ];

            /**
             * Record containing durations and their corresponding option metadata.
             */
            this.PresetRecord = this.PresetDurations.reduce((record, value) => {
                const duration = DurationISO.parse(value);
                const label = DurationISO.label(duration);
                return {
                    ...record,
                    [value]: {
                        label,
                        value,
                        duration,
                    },
                };
            }, /** @type {{ [value: string]: { value: string, label: string, duration: Duration } }} */ ({}));

            /**
             * @type {FormController['formConfig']['value']}
             */
            this.DefaultFormConfig = {
                delay: 150,
                validationVisibility: 'dirty',
            };

            /**
             * Default form data instance.
             */
            this.DefaultFormData = new FormDataModel({
                start: EPOCH_DATE?.toLocaleDateString('en-CA'),
                end: EPOCH_DATE?.toLocaleDateString('en-CA'),
                all: false,
                overlap: false,
            });

            /** Date range filter tooltips. */
            this.FormTooltips = /** @type {const} */ ({
                all: 'Date range includes all data points across selected Locations.',
                overlap:
                    'Date range only includes data points from overlap between selected Locations.',
            });

            /** Default debounced watcher options. */
            this.DefaultWatcherOptions = /** @type {const} */ ({
                debounce: 25,
                maxWait: 500,
                deep: false,
                immediate: true,
                flush: 'pre',
            });
        }
    }

    // EVENTS

    /**
     * Define the event hooks.
     */
    defineEvents() {
        /** @type {Filters.DateRange.Form.Controller['events']} */
        this.events = {
            init: createEventHook(),
            input: createEventHook(),
            restore: createEventHook(),
            snapshot: createEventHook(),
        };

        /** @type {Filters.DateRange.Form.Controller['ui']} */
        this.ui = {
            node: createEventHook(),
            blur: createEventHook(),
            click: createEventHook(),
        };
    }

    // STATE

    /**
     * Define the reactive state.
     */
    defineReactive() {
        /** @type {FormController['formConfig']} */
        this.formConfig = ref({ ...this.DefaultFormConfig });

        /** @type {FormController['formData']} */
        this.formData = ref(
            FormDataModel.create({
                ...this.DefaultFormData,
                ...this.store.state.analysis?.filters?.daterange?.toFormModel(),
            })
        );

        /** @type {Vue.Ref<{ [K in Filters.DateRange.Input]?: import('@formkit/core').FormKitNode }>} */
        this.formNodes = ref({
            start: null,
            end: null,
        });
    }

    /**
     * Define the computed state.
     */
    defineComputed() {
        // ==== VUEX STORE ====
        {
            /** The account details in the Vuex store. */
            this.account = computedEager(
                () => this.store.state?.accounts?.account
            );

            /** Computed reference to the sidebar filters. */
            this.sidebar = computedEager(
                () => this.store.state?.analysis?.filters
            );

            /** Computed reference to the timezone filter. */
            this.timezoneFilter = computedEager(
                () => this.store.state?.analysis?.filters?.timezone
            );

            /** Computed reference to the locations resource index. */
            this.locationsIndex = computedEager(
                () => this.store.state?.cache?.locations?.index
            );

            /** Computed reference to the locations filter. */
            this.locationsFilter = computedEager(
                () => this.store.state?.analysis?.filters?.locations
            );

            /** Computed list of checked location nodes. */
            this.checkedLocationNodes = computedEager(() => {
                const filter = this.locationsFilter.value;
                const nodes = Object.values(filter?.tree?.nodes ?? {});
                return nodes?.filter(
                    (node) =>
                        node?.type === 'Location' &&
                        node?.state?.checked === true
                );
            });

            /** Computed list of location models. */
            this.checkedLocations = computedEager(() => {
                const index = this.locationsIndex.value;
                const checked = this.checkedLocationNodes.value;
                const ids = checked
                    .map((node) => Number.parseInt(node.id?.substring(1)))
                    .filter((id) => !Number.isNaN(id));
                const resources = ids
                    .map((id) => index.get(id))
                    .filter((resource) => !is.nullOrUndefined(resource));
                return resources;
            });

            /** Computed reference to the locations resource index. */
            this.weatherStationsIndex = computedEager(
                () => this.store.state?.cache?.stations?.index
            );

            /** Computed reference to the locations filter. */
            this.weatherStationsFilter = computedEager(
                () => this.store.state?.analysis?.filters?.stations
            );

            /** Computed list of checked weather station nodes. */
            this.checkedWeatherStationNodes = computedEager(() => {
                const filter = this.weatherStationsFilter.value;
                const nodes = Object.values(filter?.tree?.nodes ?? {});
                return nodes?.filter(
                    (node) =>
                        node?.type === 'Station' &&
                        node?.state?.checked === true
                );
            });

            /** Computed list of weather station models. */
            this.checkedWeatherStations = computedEager(() => {
                const index = this.weatherStationsIndex.value;
                const checked = this.checkedWeatherStationNodes.value;
                const ids = checked.map((node) => node.id?.substring(1));
                const resources = ids
                    .map((id) => index.get(id))
                    .filter((resource) => !is.nullOrUndefined(resource));
                return resources;
            });
        }

        // ==== FORM CONTROLLER ====
        {
            /** @type {FormController['formCache']} */
            this.formCache = computedEager(() => {
                const cache =
                    this.store.state.analysis?.filters?.daterange?.clone();
                return cache?.toFormModel();
            });

            /** Tracks the current node validity of each input. */
            this.formValidity = computedEager(() => {
                const nodes = Object.values(this.formNodes.value);
                return nodes.every((node) => {
                    const isDefined = !is.nullOrUndefined(node);
                    return isDefined && node.context?.state?.valid === true;
                });
            });

            /** @type {FormController['formErrors']} */
            this.formErrors = computed(() => {
                /** @type {{ [K in keyof Filters.DateRange.Form.Data]: string[] }} */
                const bags = {
                    start: this.startDateErrors.value,
                    end: this.endDateErrors.value,
                    all: [],
                    overlap: [],
                };

                // Return now-populated error bags.
                return bags;
            });

            /** @type {FormController['isErrorPresent']} */
            this.isErrorPresent = computed(() => {
                const bags = Object.entries(this.formErrors.value);
                const bagsWithErrors = bags.filter(
                    ([id, messages]) => messages.length > 0
                );
                return bagsWithErrors.length > 0;
            });

            /** @type {Readonly<Vue.Ref<string[]>>} */
            this.startDateErrors = computed(() => {
                // Get the appropriate input value.
                const { start } = this.formData.value;

                // Compute the error record.
                const errors = {
                    'No start date provided.': this.isStartDateMissing.value,
                    'Start date must be equal to or before the end date.':
                        this.isStartAfterEnd.value,
                    'Start date value cannot be parsed.': isValidDate(
                        new Date(start)
                    ),
                };

                // Map into array of messages.
                return Object.entries(errors)
                    .filter(([_, isVisible]) => isVisible)
                    .map(([message]) => message);
            });

            /** @type {Readonly<Vue.Ref<string[]>>} */
            this.endDateErrors = computed(() => {
                // Get the appropriate input value.
                const { end } = this.formData.value;

                // Compute the error record.
                const errors = {
                    'No end date provided.': this.isEndDateMissing.value,
                    'End date must be equal to or after the start date.':
                        this.isStartAfterEnd.value,
                    'End date value cannot be parsed.': isValidDate(
                        new Date(end)
                    ),
                };

                // Map into array of messages.
                return Object.entries(errors)
                    .filter(([_, isVisible]) => isVisible)
                    .map(([message]) => message);
            });
        }

        // ==== TIMEZONE FILTER ====
        {
            /** The account model's current timezone. */
            this.accountTimezone = computed(
                () => this.account.value?.timezone ?? 'UTC'
            );

            /** The timezone filter's selected timezone. */
            this.customTimezone = computed(
                () => this.timezoneFilter.value?.timezone ?? 'UTC'
            );

            /** Computed flag indicating if the account timezone should be used for display purposes, derived from TimezoneFilter state. */
            this.isAccountTimezoneInherited = computed(
                () => this.timezoneFilter.value?.useAccountTimezone === true
            );

            /** @type {FormController['displayTimezone']} */
            this.displayTimezone = computed(() => {
                return this.isAccountTimezoneInherited.value
                    ? this.accountTimezone.value
                    : this.customTimezone.value;
            });
        }

        // ==== DATE RANGE INPUTS ====
        {
            /** @type {FormController['presetOptions']} */
            this.presetOptions = computedEager(() => {
                // The allowed durations.
                const allowed = [
                    'P1MT0S',
                    'P3MT0S',
                    'P6MT0S',
                    'P1YT0S',
                    'P3YT0S',
                ];

                // Map the allowed presets into dropdown options.
                return allowed.map((preset) => {
                    // Get the preset details.
                    const { label, value, duration } =
                        this.PresetRecord?.[preset];

                    // Create the option.
                    const option = DropdownOption.create(label, value);

                    // Register the 'onSelected' callback listener.
                    option.onSelected((item) => {
                        // Get the timestamp at selection time.
                        const timestamp = new Date();
                        // Fire the `input` event using the preset option.
                        this.input({
                            key: 'preset',
                            value: { label, timestamp, duration },
                        });
                    });

                    // Return the prepared option.
                    return option;
                });
            });

            /** @type {FormController['dateInterval']} */
            this.dateInterval = computedEager(() => {
                const { start, end } = this.formData.value;
                return { start, end };
            });

            /** @type {FormController['isDateInputEnabled']} */
            this.isDateInputEnabled = computed(
                () => this.isEveryModifierDisabled.value === true
            );

            /** @type {FormController['isDateInputDisabled']} */
            this.isDateInputDisabled = computed(
                () => this.isDateInputEnabled.value !== true
            );

            /** @type {FormController['isDateRangeIncomplete']} */
            this.isDateRangeIncomplete = computed(() => {
                return (
                    this.isStartDateMissing.value || this.isEndDateMissing.value
                );
            });

            /** @type {FormController['isStartDateMissing']} */
            this.isStartDateMissing = computedEager(() => {
                const { start } = this.formData.value;
                return start.length === 0;
            });

            /** @type {FormController['isEndDateMissing']} */
            this.isEndDateMissing = computedEager(() => {
                const { end } = this.formData.value;
                return end.length === 0;
            });

            /** @type {FormController['isStartBeforeEnd']} */
            this.isStartBeforeEnd = computed(() => {
                const { start, end } = this.formData.value;
                return this.isDateRangeIncomplete.value
                    ? false
                    : start !== end && start.localeCompare(end) < 0;
            });

            /** @type {FormController['isStartEqualToEnd']} */
            this.isStartEqualToEnd = computed(() => {
                const { start, end } = this.formData.value;
                return this.isDateRangeIncomplete.value
                    ? false
                    : start === end || start.localeCompare(end) === 0;
            });

            /** @type {FormController['isStartAfterEnd']} */
            this.isStartAfterEnd = computed(() => {
                const { start, end } = this.formData.value;
                return this.isDateRangeIncomplete.value
                    ? false
                    : start !== end && start.localeCompare(end) > 0;
            });
        }

        // ==== DATE RANGE MODIFIERS ====
        {
            /** @type {FormController['checked']} */
            this.checked = computed(() => {
                // DESTRUCTURE modifier flags.
                const { all, overlap } = this.formData.value;

                /** @type {Array<[ key: Filters.DateRange.Modifier, value: Filters.DateRange.ModifierValue ]>} */
                const modifiers = /** @type {any} */ (
                    Object.entries({ all, overlap })
                );

                /** @type {Set<Filters.DateRange.Modifier>} */
                const initial = new Set();

                // REDUCE key/value pairs into single set.
                return modifiers.reduce((list, entry) => {
                    const [key, isEnabled] = entry;
                    return isEnabled ? list.add(key) : list;
                }, initial);
            });

            /** @type {FormController['isAnyModifierEnabled']} */
            this.isAnyModifierEnabled = computed(() => {
                const modifiers = new Set(this.checked.value);
                return modifiers.size > 0;
            });

            /** @type {FormController['isEveryModifierDisabled']} */
            this.isEveryModifierDisabled = computed(() => {
                const modifiers = new Set(this.checked.value);
                return modifiers.size === 0;
            });

            /** @type {FormController['isAllDatesModifierEnabled']} */
            this.isAllDatesModifierEnabled = computed(() => {
                const modifiers = new Set(this.checked.value);
                return modifiers.has('all');
            });

            /** @type {FormController['isOverlappingDatesModifierEnabled']} */
            this.isOverlappingDatesModifierEnabled = computed(() => {
                const modifiers = new Set(this.checked.value);
                return modifiers.has('overlap');
            });
        }
    }

    /**
     * Define event triggers.
     */
    defineTriggers() {
        const { init, input, restore, snapshot } = this.events;
        this.initialize = init.trigger;
        this.input = input.trigger;
        this.restore = restore.trigger;
        this.snapshot = snapshot.trigger;

        const { node, blur, click } = this.ui;
        this.registerNode = node.trigger;
        this.blur = blur.trigger;
        this.click = click.trigger;
    }

    /**
     * Define event listener hooks.
     */
    defineHooks() {
        const { init, input, restore, snapshot } = this.events;
        this.onFilterInitialized = init.on;
        this.onInput = input.on;
        this.onRestore = restore.on;
        this.onSnapshot = snapshot.on;

        const { node, blur, click } = this.ui;
        this.onNodeRegistered = node.on;
        this.onBlur = blur.on;
        this.onClick = click.on;
    }

    /**
     * Define getter functions for querying form and store state.
     */
    defineQueries() {
        /** Query property value from the store. */
        this.queryStartDateFromStore = () => {
            const data = this.store.state.analysis.filters.daterange.clone();
            return data.start ?? '';
        };

        /** Query property value from the store. */
        this.queryEndDateFromStore = () => {
            const data = this.store.state.analysis.filters.daterange.clone();
            return data.end ?? '';
        };

        /** Query property value from the store. */
        this.queryAllDatesModifierFromStore = () => {
            const data = this.formCache.value;
            return data.all === true;
        };

        /** Query property value from the store. */
        this.queryOverlappingDatesModifierFromStore = () => {
            const data = this.formCache.value;
            return data.overlap === true;
        };
    }

    /**
     * Define the watcher factories.
     */
    defineWatchers() {
        /** @type {FormController['watchDateRangeComponents']} */
        this.watchDateRangeComponents = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                immediate: false,
            };

            // Helper functions.
            /** @type {(current: string[], previous: string[]) => boolean} */
            const isDirty = (current, previous) => !compare(current, previous);

            // Create the debounced watcher.
            return watchDebounced(
                [this.queryStartDateFromStore, this.queryEndDateFromStore],
                (current, previous, onCleanup) => {
                    if (isDirty(current, previous)) {
                        // Mark occurrence.
                        // console.log(
                        //     '[daterange::dates]',
                        //     diff(current, previous, jsonPatchPathConverter)
                        // );
                        // Get the store model properties.
                        const [start, end] = current;
                        // Fire the restore event for the store model.
                        this.restore({
                            timestamp: new Date(),
                            data: {
                                start,
                                end,
                            },
                        });
                    }
                },
                options
            );
        };

        /** @type {FormController['watchDateRangeModifiers']} */
        this.watchDateRangeModifiers = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = this.DefaultWatcherOptions;

            // Helper functions.
            /** @type {(current: boolean[], previous: boolean[]) => boolean} */
            const isDirty = (current, previous) => !compare(current, previous);

            // Create the debounced watcher.
            return watchDebounced(
                [
                    this.queryAllDatesModifierFromStore,
                    this.queryOverlappingDatesModifierFromStore,
                ],
                (current, previous, onCleanup) => {
                    if (isDirty(current, previous)) {
                        // Mark occurrence.
                        // console.log(
                        //     '[daterange::modifiers]',
                        //     diff(current, previous, jsonPatchPathConverter)
                        // );

                        // Get the store model properties.
                        const [all, overlap] = current;

                        // If all modifier is enabled, use the all dates.
                        if (all === true) {
                            let range = this.calculateTotalInterval();
                            if (
                                is.nullOrUndefined(range) ||
                                is.emptyStringOrWhitespace(range.start) ||
                                is.emptyStringOrWhitespace(range.end)
                            ) {
                                // If calculation not available,
                                // fallback to last used values.
                                range = {
                                    start: this.formData.value.start,
                                    end: this.formData.value.end,
                                };
                            }
                            this.snapshot({
                                timestamp: new Date(),
                                data: {
                                    start: range.start,
                                    end: range.end,
                                    all: true,
                                    overlap: false,
                                },
                            });
                            this.formData.value.overlap = false;
                            return;
                        }

                        // If overlap modifier is enabled, use the overlap dates.
                        if (overlap === true) {
                            let range = this.calculateOverlapInterval();
                            if (
                                is.nullOrUndefined(range) ||
                                is.emptyStringOrWhitespace(range.start) ||
                                is.emptyStringOrWhitespace(range.end)
                            ) {
                                // If calculation not available,
                                // fallback to last used values.
                                range = {
                                    start: this.formData.value.start,
                                    end: this.formData.value.end,
                                };
                            }
                            this.snapshot({
                                timestamp: new Date(),
                                data: {
                                    start: range.start,
                                    end: range.end,
                                    all: false,
                                    overlap: true,
                                },
                            });
                            this.formData.value.all = false;
                            return;
                        }
                    }
                },
                options
            );
        };

        /** @type {FormController['watchDisplayTimezone']} */
        this.watchDisplayTimezone = () => {
            // Prepare dependencies.
            const deps = this.displayTimezone;

            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                deep: true,
            };

            // Helper functions.
            /** @type {(current: TimeZone.Identifier, previous: TimeZone.Identifier) => boolean} */
            const isDirty = (current, previous) => !compare(current, previous);

            // Create the debounced watcher.
            return watchDebounced(
                deps,
                (current, previous, onCleanup) => {
                    if (isDirty(current, previous)) {
                        // Mark occurrence.
                        // console.log('[daterange::timezone]', {
                        //     current,
                        //     previous,
                        // });
                        // Fire `snapshot` event with current data.
                        this.snapshot({
                            timestamp: new Date(),
                            data: this.formData.value.clone(),
                        });
                    }
                },
                options
            );
        };

        /** @type {FormController['watchResourceFilters']} */
        this.watchResourceFilters = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                deep: true,
            };

            // Type defintions.

            /** @typedef {import('@/store/types/ECNBStore').LocationResource} LocationResource */
            /** @typedef {import('@/store/types/ECNBStore').WeatherStationResource} WeatherStationResource */

            // Helper functions.

            /** @type {(current: LocationResource[], previous: LocationResource[]) => boolean} */
            const isLocationSelectionDirty = (current, previous) =>
                !compare(current, previous);

            /** @type {(current: WeatherStationResource[], previous: WeatherStationResource[]) => boolean} */
            const isWeatherStationSelectionDirty = (current, previous) =>
                !compare(current, previous);

            /** @type {(current: [ locations: LocationResource[], stations: WeatherStationResource[] ], previous: [ locations: LocationResource[], stations: WeatherStationResource[] ]) => boolean}} */
            const isDirty = (current, previous) => {
                return (
                    isLocationSelectionDirty(current[0], previous[0]) ||
                    isWeatherStationSelectionDirty(current[1], previous[1])
                );
            };

            // Create the debounced watcher.
            return watchDebounced(
                [this.checkedLocations, this.checkedWeatherStations],
                (current, previous, onCleanup) => {
                    const _current =
                        /** @type {[ locations: LocationResource[], stations: WeatherStationResource[] ]} */ (
                            current
                        );
                    const _previous =
                        /** @type {[ locations: LocationResource[], stations: WeatherStationResource[] ]} */ (
                            previous
                        );
                    if (isLocationSelectionDirty(_current[0], _previous[0])) {
                        // Mark occurrence.
                        // console.log(
                        //     '[daterange::resources]',
                        //     diff(
                        //         current[0],
                        //         previous[1],
                        //         jsonPatchPathConverter
                        //     )
                        // );
                        // console.dir({ current: _current[0] });

                        // Get the modifier settings.
                        const { all, overlap } = this.formData.value;

                        // If all modifier is enabled, use the all dates.
                        if (all === true) {
                            let range = this.calculateTotalInterval();
                            if (
                                is.nullOrUndefined(range) ||
                                is.emptyStringOrWhitespace(range.start) ||
                                is.emptyStringOrWhitespace(range.end)
                            ) {
                                // If calculation not available,
                                // fallback to last used values.
                                range = {
                                    start: this.formData.value.start,
                                    end: this.formData.value.end,
                                };
                            }
                            this.snapshot({
                                timestamp: new Date(),
                                data: {
                                    start: range.start,
                                    end: range.end,
                                    all: true,
                                    overlap: false,
                                },
                            });
                            this.formData.value.overlap = false;
                            return;
                        }

                        // If overlap modifier is enabled, use the overlap dates.
                        if (overlap === true) {
                            let range = this.calculateOverlapInterval();
                            if (
                                is.nullOrUndefined(range) ||
                                is.emptyStringOrWhitespace(range.start) ||
                                is.emptyStringOrWhitespace(range.end)
                            ) {
                                // If calculation not available,
                                // fallback to last used values.
                                range = {
                                    start: this.formData.value.start,
                                    end: this.formData.value.end,
                                };
                            }
                            this.snapshot({
                                timestamp: new Date(),
                                data: {
                                    start: range.start,
                                    end: range.end,
                                    all: false,
                                    overlap: true,
                                },
                            });
                            this.formData.value.all = false;
                            return;
                        }
                    }
                },
                options
            );
        };

        /** @type {FormController['watchFormAllDatesModifierToggle']} */
        this.watchFormAllDatesModifierToggle = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                immediate: true,
            };

            // Create the debounced watcher.
            return watchDebounced(
                () => this.formData.value?.all ?? false,
                (current, previous, onCleanup) => {
                    // Update the node if dirty.
                    if (!compare(current, previous)) {
                        // console.log('[flush::all] <daterange>', {
                        //     current,
                        //     previous,
                        // });
                        // Push change to the input.
                        this.input({ key: 'all', value: current });
                    }
                },
                options
            );
        };

        /** @type {FormController['watchFormOverlapDatesModifierToggle']} */
        this.watchFormOverlapDatesModifierToggle = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                immediate: true,
            };

            // Create the debounced watcher.
            return watchDebounced(
                () => this.formData.value?.overlap ?? false,
                (current, previous, onCleanup) => {
                    // Update the node if dirty.
                    if (!compare(current, previous)) {
                        // console.log('[flush::overlap] <daterange>', {
                        //     current,
                        //     previous,
                        // });
                        // Push change to the input.
                        this.input({ key: 'overlap', value: current });
                    }
                },
                options
            );
        };

        /** @type {FormController['watchFormStartDate']} */
        this.watchFormStartDate = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                immediate: true,
            };

            // Create the debounced watcher.
            return watchDebounced(
                () => this.formData.value?.start ?? '',
                (current, previous, onCleanup) => {
                    // Update the node if dirty.
                    if (!compare(current, previous)) {
                        // console.log('[flush::start] <daterange>', {
                        //     current,
                        //     previous,
                        // });
                        const node = this.formNodes.value?.start;
                        node?.input(current ?? '', false);
                    }
                },
                options
            );
        };

        /** @type {FormController['watchFormEndDate']} */
        this.watchFormEndDate = () => {
            /** Watcher options. @type {import('@vueuse/core').WatchDebouncedOptions<boolean>} */
            const options = {
                ...this.DefaultWatcherOptions,
                immediate: true,
            };

            // Create the debounced watcher.
            return watchDebounced(
                () => this.formData.value?.end ?? '',
                (current, previous, onCleanup) => {
                    // Update the node if dirty.
                    if (!compare(current, previous)) {
                        // console.log('[flush::end] <daterange>', {
                        //     current,
                        //     previous,
                        // });
                        const node = this.formNodes.value?.end;
                        node?.input(current ?? '', false);
                    }
                },
                options
            );
        };
    }

    /**
     * Define controller actions.
     */
    defineActions() {
        /** Register the event listeners. */
        this.registerEventListeners = () => {
            // When the filter is initialized, read data from the store.
            this.onFilterInitialized(({ id, type }) => {
                // console.log(`[init::daterange] ${id} ${type}`);

                /**
                 * On unmount, snapshot the current form data
                 * and save it to the Vuex store for usage in
                 * a different tab / context.
                 */
                if (type === 'unmount') {
                    this.snapshot({
                        timestamp: new Date(),
                        data: this.formData.value.clone(),
                    });
                }

                /**
                 * On mount, restore the last used form data
                 * from the Vuex store and overwrite anything
                 * in the current date range input.
                 */
                if (type === 'mount') {
                    this.restore({
                        timestamp: new Date(),
                        data: this.formCache.value.clone(),
                    });
                }
            });

            // When input event is received, it is processed here.
            this.onInput(async ({ key, value }) => {
                switch (key) {
                    case 'preset':
                        // Dispatch store action to use the specific preset.
                        await this.useDateRangePreset(value);
                        return;
                    case 'start':
                    case 'end':
                        // Dispatch store action to use the update date range component.
                        await this.useDateRangeComponent(key, value);
                        return;
                    case 'all':
                    case 'overlap':
                        // Dispatch store action to apply the modifier state.
                        await this.useDateRangeModifier(key, value);
                        return;
                    default:
                        console.warn(`Unexpected input event '${key}'.`);
                        break;
                }
            });

            // "Restore" will write the event data to the UI data model.
            this.onRestore((event) => {
                // console.log(`[restore::daterange]`, event);
                Object.assign(this.formData.value, event.data);
            });

            // "Snapshot" will write the event data to the Vuex store.
            this.onSnapshot((event) => {
                // console.log(`[snapshot::daterange]`, event);
                // Get the store model with overridden values applied.
                const model = FormDataModel.create({
                    ...this.formCache.value,
                    ...event.data,
                });
                // Fire mutation to snapshot the data.
                this.store.dispatch(
                    'analysis/saveDateRangeFilter',
                    model.toCacheModel()
                );
            });

            // "Register" date input node reference.
            this.onNodeRegistered(({ id, node }) => {
                console.log(`[node::${id}]`, node);
                if (id in this.formNodes.value) {
                    this.formNodes.value[id] = node;
                }
            });

            // "Blur" will handle the dropdown menu event.
            this.onBlur(({ id, event }) => {
                if (id === 'dropdown-menu') {
                    // console.log(`[focusout::dropdown]`, event);
                    const element = document?.getElementById(id);
                    element?.blur();
                }
            });
        };

        /**
         * Handle the `@node` event.
         * @param {import('@formkit/core').FormKitNode} node
         */
        this.handleFormKitNode = (node) => {
            const id = /** @type {Filters.DateRange.Input} */ (node.name);
            this.registerNode({ id, node });
        };

        /**
         * Handle the `@focusout` event for the Preset Date Range menu dropdown.
         * @param {FocusEvent} event
         */
        this.handleFocusOutMenuDropdown = (event) => {
            this.blur({ id: 'dropdown-menu', event });
        };

        /**
         * Handle the `@click` event for the modifier toggles.
         * @param {*} target
         * @param {*} value
         */
        this.handleDateRangeModifierToggle = (target, value) => {
            this.input({ key: target, value });
        };

        /**
         * Handle the date input.
         * @param {string} value
         * @param {import("@formkit/core").FormKitNode} node
         */
        this.handleDateLocalInput = (value, node) => {
            const key = /** @type {Filters.DateRange.Input} */ (node.name);
            this.input({ key, value });
        };

        /**
         * Handle the click event on a date input.
         * @param {Filters.DateRange.Input} key
         * @param {PointerEvent} event
         */
        this.handleDateLocalClicked = (key, event) => {
            const { target } = event;
            const previous = this.formData.value?.[key] ?? '';
            const current =
                target instanceof HTMLInputElement ? target?.value : '';

            // Only simulate event on click when value is dirty.
            if (!compare(current, previous)) {
                this.simulateDateLocalInput(key, event);
            }
        };

        /**
         * Handle the keyboard event on a date input.
         * @param {Filters.DateRange.Input} key
         * @param {KeyboardEvent} event
         */
        this.handleDateLocalKeyboardEvent = (key, event) => {
            if (event.type === 'keyup' && event.key === 'Enter') {
                this.simulateDateLocalInput(key, event);
            }
        };

        /**
         * Simulate date input event.
         * @param {Filters.DateRange.Input} key
         * @param {PointerEvent | KeyboardEvent} event
         */
        this.simulateDateLocalInput = (key, event) => {
            // Simulate the input event.
            const node = this.formNodes.value?.[key];
            const target = event?.target;
            if (
                !!node &&
                !!target &&
                target instanceof HTMLInputElement &&
                target.type === 'date'
            ) {
                const { value } = target;
                this.handleDateLocalInput(value, node);
            }
        };

        /**
         * Check if the given node is valid.
         * @param {string} name
         */
        this.isNodeValid = (name) => {
            const node = Object.values(this.formNodes.value).find(
                (node) => node?.name === name
            );
            return node?.context?.state?.valid === true;
        };

        /**
         * Dispatch the use date range preset action.
         * @param {Filters.DateRange.Form.PresetSelectedEvent['value']} event
         */
        this.useDateRangePreset = async (event) => {
            // console.log(`Selected preset ${event?.label}.`);

            // Disable the modifiers.
            this.formData.value = Object.assign(this.formData.value, {
                all: false,
                overlap: false,
            });

            await this.store.dispatch('analysis/useDateRangePreset', event);
        };

        /**
         * Conditionally dispatch the date range changes.
         * @param {Filters.DateRange.Input} key
         * @param {DateString} value
         */
        this.useDateRangeComponent = async (key, value) => {
            // Compute the updated date range.
            const data = this.formCache.value.clone();
            data[key] = value.trim();

            // Get the components.
            const { start, end } = data;

            // NOTE: Due to the YYYY-MM-DD format, we can
            // compare date values using localeCompare on
            // their strings alone.
            const isStartAfterEnd = start.localeCompare(end) > 0;

            // Adjust the candidate date range such that
            // the changed component's complement remains
            // a valid date.
            if (isStartAfterEnd) {
                // Set the complement to the current value
                // since the dates can simply be equivalent
                // (eg., set it to a duration of 1 valid day).
                const clamped = key === 'start' ? 'end' : 'start';
                data[clamped] = data[key];
            }

            // Dispatch the date range update.
            await this.useDateRange(data);
        };

        /**
         * Conditionally dispatch the date range changes.
         * @param {NominalDateRange} payload
         */
        this.useDateRange = async (payload) => {
            // Use trimmed value as the current input
            console.groupCollapsed(
                `[useDateRange] ${payload.start} to ${payload.end}`
            );
            // console.log(`Selected date range.`, payload);
            // Dispatch the date range update, if dirty.
            await this.store.dispatch('analysis/useDateRange', payload);
            console.groupEnd();
        };

        /**
         * Conditionally dispatch the date range modifier changes.
         * @param {Filters.DateRange.Modifier} target
         * @param {boolean} isEnabled
         */
        this.useDateRangeModifier = async (target, isEnabled) => {
            // Specifically enable the all dates modifier.
            if (target === 'all' && isEnabled) {
                await this.useAllDatesModifier();
                return;
            }
            // Specifically enable the overlapping dates modifier.
            if (target === 'overlap' && isEnabled) {
                await this.useOverlapDatesModifier();
                return;
            }
            // Specifically disable the modifier by its target name.
            await this.disableModifierByName(target);
            return;
        };

        /**
         * Disable the modifier.
         * @param {Filters.DateRange.Modifier} key
         */
        this.disableModifierByName = async (key) => {
            // Get the checked modifiers.
            const checked = new Set(
                StoreDataModel.computeCheckedModifiers(this.formCache.value)
            );

            // Remove the target modifier.
            checked.delete(key);

            // Dispatch event.
            await this.store.dispatch(
                'analysis/useDateRangeModifiers',
                checked
            );
        };

        /**
         * Enable the all dates modifier.
         * - Do nothing, if it is already enabled.
         * - Disable all other modifiers.
         */
        this.useAllDatesModifier = async () => {
            // Get the currently checked modifiers.
            const checked = new Set(
                StoreDataModel.computeCheckedModifiers(this.formCache.value)
            );

            // If not already checked, then check it and disable the other modifiers.
            if (!checked.has('all')) {
                // Remove the overlap modifier if it's present.
                checked.delete('overlap');
                // Add the 'all' modifier.
                checked.add('all');
                // Fire off the dispatcher.
                this.store.dispatch('analysis/useDateRangeModifiers', checked);
            }
        };

        /**
         * Enable the overlap dates modifier.
         * - Do nothing, if it is already enabled.
         * - Disable all other modifiers.
         */
        this.useOverlapDatesModifier = async () => {
            // Get the currently checked modifiers.
            const checked = new Set(
                StoreDataModel.computeCheckedModifiers(this.formCache.value)
            );

            // If not already checked, then check it and disable the other modifiers.
            if (!checked.has('overlap')) {
                // Remove the all modifier if it's present.
                checked.delete('all');
                // Add the 'overlap' modifier.
                checked.add('overlap');
                // Fire off the dispatcher.
                this.store.dispatch('analysis/useDateRangeModifiers', checked);
            }
        };

        /**
         * Calculate the total interval.
         * @returns {NominalDateRange}
         */
        this.calculateTotalInterval = () => {
            // Prepare tracking variables.
            const resources = this.checkedLocations.value;

            // Exit early if no checked locations are available.
            if (is.nullOrUndefined(resources) || resources.length <= 0) {
                // Use the current date range.
                return this.formData.value?.clone();
            }

            // Find the minimum and maximum dates.
            // console.dir(resources);

            /** @type {[ start: DateLike, end: DateLike ][]} */
            const series = resources.map((location) => {
                // Get the ISO date strings.
                const minISO = location?.minDate ?? '';
                const maxISO = location?.maxDate ?? '';

                // Validate the date strings.
                const isMissingMinDate =
                    is.nullOrUndefined(minISO) ||
                    is.emptyStringOrWhitespace(minISO);
                const isMissingMaxDate =
                    is.nullOrUndefined(maxISO) ||
                    is.emptyStringOrWhitespace(maxISO);

                // Parse the dates.
                const minDate = isMissingMinDate ? NaN : new Date(minISO);
                const maxDate = isMissingMaxDate ? NaN : new Date(maxISO);

                // Map into the appropriate interval.
                return [minDate, maxDate];
            });

            // Caclulate the total fixed date range from a series of ranges.
            const interval = getDateRangeTotal(series);

            // Get the formatter.
            const dateFormatter = new Intl.DateTimeFormat('en-CA', {
                timeZone: this.displayTimezone.value,
            });

            // Coerce into a nominal date range.
            const range = {
                start: dateFormatter.format(interval?.start),
                end: dateFormatter.format(interval?.end),
            };

            // Return the nominal date range.
            return range;
        };

        /**
         * Calculate the overlap interval.
         * @returns {NominalDateRange | null}
         */
        this.calculateOverlapInterval = () => {
            // Prepare tracking variables.
            const resources = this.checkedLocations.value;

            // Exit early if no checked locations are available.
            if (is.nullOrUndefined(resources) || resources.length <= 0) {
                // Use the current date range.
                return this.formData.value?.clone();
            }

            /** @type {[ start: DateLike, end: DateLike ][]} */
            const series = resources.map((location) => {
                // Get the ISO date strings.
                const minISO = location?.minDate ?? '';
                const maxISO = location?.maxDate ?? '';

                // Validate the date strings.
                const isMissingMinDate =
                    is.nullOrUndefined(minISO) ||
                    is.emptyStringOrWhitespace(minISO);
                const isMissingMaxDate =
                    is.nullOrUndefined(maxISO) ||
                    is.emptyStringOrWhitespace(maxISO);

                // Parse the dates.
                const minDate = isMissingMinDate ? NaN : new Date(minISO);
                const maxDate = isMissingMaxDate ? NaN : new Date(maxISO);

                // Map into the appropriate interval.
                return [minDate, maxDate];
            });

            // Get the formatter.
            const dateFormatter = new Intl.DateTimeFormat('en-CA', {
                timeZone: this.displayTimezone.value,
            });

            // Initialize the overlapping range object.
            const range = { start: '', end: '' };

            // CASE: Only one location is provided.
            if (series.length === 1) {
                // Compute the overlap interval from series of 1 entry.
                console.log(
                    '[calculateOverlapInterval()]: Only 1 location selected. Using min/max date range.'
                );

                // Write the range.
                range.start = dateFormatter.format(series[0][0]);
                range.end = dateFormatter.format(series[0][1]);

                // Return the overlap interval range.
                return range;
            }

            // CASE: Multiple locations are provided.
            if (series.length > 1) {
                /**
                 * The overlapping range.
                 * @type {Interval}
                 *
                 * Terms are initialized to `NaN`.
                 * The variable should be set to `null` when no overlap exists.
                 */
                const overlap = { start: NaN, end: NaN };

                // Find the overlap using a short-circuiting 'every()' iterator with side-effects.
                const hasOverlap = series
                    .sort((a, b) => a[0]?.valueOf() - b[0]?.valueOf())
                    .map((range) => ({ start: range[0], end: range[1] }))
                    .every((interval, index) => {
                        if (index === 0) {
                            // CASE: This is the first date in the list.
                            overlap.start = interval.start;
                            overlap.end = interval.end;
                        } else {
                            // CASE: This is a future date in the list.
                            // Find the latest possible start date.
                            if (
                                interval.start.valueOf() >=
                                overlap.start.valueOf()
                            ) {
                                overlap.start = interval.start;
                            }
                            // Find the earliest possible end date.
                            if (
                                interval.end.valueOf() <= overlap.end.valueOf()
                            ) {
                                overlap.end = interval.end;
                            }
                        }

                        // Ensure the overlap start is before the end.
                        if (overlap.start.valueOf() > overlap.end.valueOf()) {
                            // Invalid configuration. Fail the check.
                            overlap.start = NaN;
                            overlap.end = NaN;
                            return false;
                        }

                        // Return true if a valid overlap was successfully calculated,
                        // which should continue the `every()` iterator.
                        return true;
                    });

                // If an overlap was successfully calculated, update the range.
                if (hasOverlap) {
                    // Write the range.
                    range.start = dateFormatter.format(overlap.start);
                    range.end = dateFormatter.format(overlap.end);
                }
            }

            // Return the nominal date range.
            return range;
        };
    }
}

// <!-- COMPOSABLE -->
/**
 * Composable feature for managing the date range filter.
 */
export const useDateRangeFilter = () => new Controller();

// <!-- DEFAULT -->
export default useDateRangeFilter;
