<template>
    <span
        :class="['modal__trigger', 'relative cursor-help rounded-lg']"
        :tabindex="0"
        @keyup.enter.stop="$emit('open', $event)"
        @click.stop="$emit('open', $event)"
    >
        <slot>
            <BaseTooltip
                class="inline-block"
                target="#tooltip-target"
                text="View NARA Standards."
            >
                <template #icon>
                    <InformationCircleIcon
                        :class="[
                            'relative inline-block left-1 -top-0.5',
                            'h-6 w-6 sm:h-4 sm:w-4',
                            'bg-transparent hover:bg-info hover:bg-opacity-10',
                            'text-primary-700 hover:text-info',
                            'transform scale-100 hover:scale-125',
                            'transition-all duration-300 ease-in-out',
                            'rounded-lg',
                        ]"
                        aria-hidden="true"
                    />
                </template>
            </BaseTooltip>
        </slot>
    </span>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';
    import { InformationCircleIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'TeleportTrigger',
        components: {
            BaseTooltip,
            InformationCircleIcon,
        },
        emits: ['open'],
    });
</script>
