// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { isDateWithinInterval } from '@/features/analysis/utils';

// <!-- TYPES -->
/** @typedef {import('@/utils/datetime').IDate} IDate */
/** @typedef {import('@/utils/datetime').IInterval} IInterval */
/** @typedef {import('@/utils/datetime').ITimestamp} ITimestamp */

/**
 * Test if point is within a given interval.
 * @param {Readonly<[ x: IDate, y: any ]>} point Data point to test.
 * @param {Readonly<IInterval>} interval Interval to test.
 * @returns {Boolean}
 */
export const isPointWithinInterval = (point, interval) => {
    const isPointPresent = !isNil(point);
    const isPointDatePresent = isPointPresent && !isNil(point[0]);
    const inBounds =
        isPointPresent &&
        isPointDatePresent &&
        isDateWithinInterval(point[0], interval);
    return inBounds === true;
};

// <!-- DEFAULT -->
export default isPointWithinInterval;
