// <!-- UTILITIES -->
import startCase from 'lodash/startCase';

/** @param {String} value */
export const capitalFormatter = (value) => {
    const input = value.toLowerCase().trim();
    const output = startCase(input);

    // TODO: Figure out more robust solution.
    return output.replace('Nyc', 'NYC').replace('Jfk', 'JFK');
};
